<template>
	<div class="common-layout">
		<el-container>
			<!-- 头部 -->
			<el-header>
				<div class="left">
					<el-icon size="30">
						<Setting />
					</el-icon>
					<h1 style="margin-left:10px">Off-line equipment</h1>
				</div>
				<div class="right">
					<!-- <h1>个人信息</h1> -->
				</div>
			</el-header>
			<!-- 信息展示部分 -->
			<el-main style="display: flex;">
				<div style="width: 25%;height: 100%;">
					<el-row>
						<el-button @click="connectToSerialPort" size="small"
							round>{{port?'Reselection':'Choose Device'}}</el-button>
						<!-- <el-input v-model="inputData" maxlength="50" placeholder="输入发送数据内容" show-word-limit
							type="textarea" /> -->
						<!-- <el-button type="success" @click="sendData">发送数据</el-button> -->
						<el-button @click="cutPort" size="small" type="danger" round>Disconnect Device</el-button>
					</el-row>

					<el-row style="margin-top: 10px;padding-left: 3px;">
						<el-button @click="onclick(0)" type="success" size="small" round :disabled="!port">Read
							Speed-Fence</el-button>
						<el-button @click="sendFence" type="primary" size="small" round :disabled="!port">Set
							Speed-Fence</el-button>

						<el-popconfirm @confirm="delAllFences" title="Are you sure to reset equipment fence data？">
							<template #reference>
								<el-button type="warning" size="small" round :disabled="!port">RESET Device
									Fences</el-button>
							</template>
						</el-popconfirm>
					</el-row>

					<el-row style="margin-top: 10px;padding-left: 3px;">
						<el-upload class="upload-demo" action accept=".xlsx,.xls" :auto-upload="false"
							:show-file-list="false" :on-change="handleChange">
							<el-button size="small" type="primary" plain>Import Fences File</el-button>
						</el-upload>

						<el-button @click="setXlxs" size="small" type="primary" plain style="margin-left: 15px;">Export
							Fences File</el-button>
						<el-button @click="setXlxsModel" size="small" type="primary" plain>File Template
							Dowmload</el-button>
					</el-row>
					<el-table ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange"
						style="width: 100%;height: 80vh;">
						<el-table-column type="selection" width="40" />
						<el-table-column type="index" width="80" label="index">
						</el-table-column>
						<el-table-column prop="speed" label="type" width="80">
							<template #default="scope">
								<span v-if="scope.row.type==1">web</span>
								<span v-else>equipment</span>
							</template>
						</el-table-column>
						<el-table-column prop="speed" label="speed">
							<template #default="scope">
								<el-input style="width: 100%;" @input="nameInput(scope.row)" v-if="scope.row.flg"
									v-model="scope.row.speed" />
								<span v-else>{{scope.row.speed}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="option" label="operation" width="140">
							<template #default="scope">
								<el-button type="primary" size="small" v-show="!scope.row.flg"
									@click="edit(scope.row,scope.$index)">edit</el-button>
								<el-button type="success" size="small" v-show="scope.row.flg"
									@click="ok(scope.row,scope.$index)">ok</el-button>
								<!-- <el-button type="info" size="small" v-show="scope.row.flg"
									@click="cancel(scope.row,scope.$index)">取消</el-button> -->
								<el-popconfirm @confirm="del(scope.row,scope.$index)"
									title="Are you sure to remove this fence？">
									<template #reference>
										<el-button type="danger" size="small" v-show="!scope.row.flg">del</el-button>
									</template>
								</el-popconfirm>

							</template>
						</el-table-column>
					</el-table>

				</div>
				<!-- <p v-if="message">接收到的信息: {{ message }}</p> -->
				<div id="gMaps"></div>
				<div id="pac-container" class="flex-item2">
					<el-input v-model="localValue" ref="autocompleteInput" type="text" placeholder="输入地址"
						@input="handleInput" />
					<div style="background-color: #fff;" v-if="map">
						<ul style="margin: 0;padding: 10px;" v-if="predictions.length != 0 && localValue != ''"
							class="autocomplete-list">
							<li style="list-style: none" v-for="prediction in predictions" :key="prediction.place_id"
								@click="selectPrediction(prediction)">
								{{ prediction.description }}
							</li>
						</ul>
					</div>

				</div>

			</el-main>

		</el-container>
		<el-dialog v-model="centerDialogVisible" title="tip" width="300px" align-center :show-close="false"
			:close-on-click-modal="false">
			<div style="padding: 0 15px;height: 60px;">
				<span style="font-size: 18px;">Please stay connected</span>
				<span v-loading="loading"></span>
			</div>
			<template #center>

			</template>
		</el-dialog>
		<el-dialog v-model="centerDialogVisible2" title="tip" width="300px" align-center :show-close="false"
			:close-on-click-modal="false">
			<div style="padding: 0 15px;height: 60px;">
				<span style="font-size: 18px;">Loding...</span>
				<span v-loading="loading1"></span>
			</div>
			<template #center>

			</template>
		</el-dialog>
	</div>
</template>

<script setup>
	import {
		Setting
	} from '@element-plus/icons-vue'
	import {
		ElNotification,
		ElMessage,
		ElTable,
		ElMessageBox
	} from 'element-plus'
	import {
		onMounted,
		ref
	} from 'vue'

	import {
		gcj02towgs84,
		out_of_china,
		wgs84togcj02
	} from './utils/index.js';
	import FileSaver from 'file-saver';
	import * as xlsx from 'xlsx';
	// import { el } from 'element-plus/es/locale';

	// 串口配置
	const port = ref(null)
	const ports = ref(null)
	const reader = ref(null)
	const message = ref(null)
	const connected = ref(null)
	const serialOptions = {
		baudRate: 9600,
		flowControl: 'none',
		dataBits: 8
	};
	let map = null;
	let mapOptions = {
		center: {
			lat: 23.12464425493477,
			lng: 113.26625618737121
		},
		zoom: 13,
		mapTypeControl: false,
		disableDoubleClickZoom: true,
		streetViewControl: false,
		zoomControl: false,
	};
	const predictions = ref([]); // 存储搜索框提示的预测结果
	const localValue = ref('');
	const index = ref(null); //点击的电子围栏key
	const options = ref(null);
	const editActive = ref(false);
	const tableData = ref([]);
	let editingRow = [];
	const storageObj = ref({});
	const centerDialogVisible = ref(false)
	const centerDialogVisible2 = ref(false)

	const num = ref(0);
	const key = ref(null);
	const drawingManager = ref(null);
	const userMaps = ref({});
	const cancleFlg = ref(false);
	const fences = ref('0064035d');
	const start = ref(null);
	const defaults = ref([]);

	// 创建一个 Map 对象
	const myMap = new Map();
	const id = ref(null);
	const value = ref(null);
	const sum = ref(0); //总点个数
	const total = ref(0); //区域总数
	const newFence = ref([]);
	const loading = ref(true);
	const loading2 = ref(true);
	const localList = ref([]);
	const inputData = ref('');
	const newData = ref('');
	//设备区域
	const deviceList = ref([]);

	const multipleTableRef = ref(ElTable);
	const multipleSelection = ref([]);
	const mapLoaded = ref(false);

	const numpook = ref(1);

	const serialDatas = ref('');
	const mergedArray1 = ref('');
	const mergedArrays = ref(new Uint8Array([]));

	//导入
	const character = {
		'index': 'index',
		'speed': 'index',
		'localtion': 'index'
	}
	//多选
	function handleSelectionChange(val) {
		multipleSelection.value = val;
		let arr = [];
		sum.value = 0;
		multipleSelection.value.forEach(item => {
			arr = item.localtion.split(',');
			sum.value = sum.value + arr.length;
		})

	}

	//模板导出
	function setXlxsModel() {
		// 1.把数据转化为表格名称对应

		let arr = [{
			'index': 1,
			'speed': 'Example:30',
			'localtion': 'Latitude: Longitude,Latitude: Longitude......exmple-->23.16185420423335:113.24578556816955,...',
		}];
		//2.用于将 JSON 数据转换为 Excel 工作表中的单元格数据。
		let sheet = xlsx.utils.json_to_sheet(arr);
		//3.用于创建一个新的 Excel 工作簿对象它返回一个空白的工作簿，可以向其中添加工作表和单元格数据。
		let book = xlsx.utils.book_new();
		//4.用于向现有的 Excel 工作簿对象（Workbook）中添加一个新的工作表
		// book_append_sheet(wb, sheet, name=None)
		//参数wb是要将工作表添加到的 Workbook 对象；sheet 参数是要添加的 Worksheet 对象；name 参数是要为工作表指定的名称（如果未指定，则使用默认名称）
		xlsx.utils.book_append_sheet(book, sheet, "sheet1");
		//5.用于将 Excel 工作簿下载到本地文件系统中 book要下载的Eceial表格，第二个参数是表格名称
		xlsx.writeFile(book, `user${new Date().getTime()}.xls`);
	}

	//文件导出
	function setXlxs() {
		if (tableData.value.length <= 0) {
			ElMessage.info('No data can be exported!');
			return;
		}
		// 1.把数据转化为表格名称对应
		let arr = tableData.value.map((item, i) => {
			return {
				'index': i + 1,
				'speed': item.speed,
				'localtion': item.localtion,
			};
		});
		//2.用于将 JSON 数据转换为 Excel 工作表中的单元格数据。
		let sheet = xlsx.utils.json_to_sheet(arr);
		//3.用于创建一个新的 Excel 工作簿对象它返回一个空白的工作簿，可以向其中添加工作表和单元格数据。
		let book = xlsx.utils.book_new();
		//4.用于向现有的 Excel 工作簿对象（Workbook）中添加一个新的工作表
		// book_append_sheet(wb, sheet, name=None)
		//参数wb是要将工作表添加到的 Workbook 对象；sheet 参数是要添加的 Worksheet 对象；name 参数是要为工作表指定的名称（如果未指定，则使用默认名称）
		xlsx.utils.book_append_sheet(book, sheet, "sheet1");
		//5.用于将 Excel 工作簿下载到本地文件系统中 book要下载的Eceial表格，第二个参数是表格名称
		xlsx.writeFile(book, `user${new Date().getTime()}.xls`);
		ElMessage.success('success!');
	}

	//把二进制文件进行读取
	function readFile(file) {
		return new Promise((resolve, reject) => {
			// FileReader主要用于将文件内容读入内存，通过一系列异步接口，可以在主线程中访问本地文件.
			let reader = new FileReader();
			// 异步按字节读取文件内容，结果为文件的二进制串
			reader.readAsBinaryString(file);
			reader.onload = (ev) => {
				resolve(ev.target.result);
			};
		});
	}
	//选择文件
	const handleChange = async function(e) {
		const fileName = e.name.substring(e.name.lastIndexOf('.'));
		if (fileName.toLowerCase() != '.xls' && fileName.toLowerCase() != '.xlsx') {
			ElMessage.error('The file type can be xlx or xlxs!');
			return;
		}
		const file = e.raw; //选择的文件内容

		let data = await readFile(file); //将内容转化为二进制
		let workbook = xlsx.read(data, {
			type: "binary"
		}); //通过插件读取二进制数据 binary二进制
		//表格目录一中的数据
		let worksheet = workbook.Sheets[workbook.SheetNames[0]];
		//把数据转化为json数据格式
		data = xlsx.utils.sheet_to_json(worksheet) || [];
		//把读取的数据变为最后可以传递给服务器的数据（所在地=>address,学校名称=>shcoolName......）
		let arr = [];

		data.forEach((item) => {
			let obj = {};
			for (let key in character) {
				//  hasOwnProperty() 方法会返回一个布尔值，指示对象自身属性中是否具有指定的属性（也就是，是否有指定的键)
				//  即使属性的值是 null 或 undefined，只要属性存在，hasOwnProperty 依旧会返回 true。
				//  判断是否有规定的属性没有就终止执行
				if (!character.hasOwnProperty(key)) break;
				let v = character[key]

				obj[key] = item[v];
			}

			obj.type = 1;
			item.type = 1;
			let u = 0;
			for (let j = 0; j < tableData.value.length; j++) {

				if (item.localtion == tableData.value[j].localtion) {
					u = 1
				}
			}

			if (u == 0 && item.localtion) {
				let def = [];
				let pam = item.localtion.split(',');
				for (let s of pam) {
					let n = s.split(':');
					let obj2 = {
						"lat": Number(n[0]),
						"lng": Number(n[1])
					}
					def.push(obj2);
				}
				let key = '1_' + tableData.value.length;
				obj.id = key;
				item.id = key;
				tableData.value.push(item);
				// tableData.value.push(obj);
				// 新建一个polygon
				let polygon = new google.maps.Polygon({
					paths: def
				});

				// 设置地图
				polygon.setMap(map);
				// 监听操作
				polygonListener(key, polygon);
				arr.push(item);
			}
		})

		//将导入数据展示到页面中

		let Arr = setLocation(arr);

		if (arr.length > 0) {
			const res = arr[arr.length - 1].localtion.split(',');
			const m = res[0].split(':');
			const newCenter = {
				lat: Number(m[0]),
				lng: Number(m[1])
			}; // 新的中心坐标
			map.setCenter(newCenter);
			map.setZoom(13);
		}
		ElMessage.success('success')
		// console.log(data, arr);
	}



	//获得十进制转换;
	function convert1(str, type) {
		let num = parseInt(str, type);
		return num;
	}
	//十进制转换其它进制;
	function convert2(str, type) {
		let num = str.toString(type);
		return num;
	}
	//判断二进制1号位
	function getNumber(e) {
		let n = 0;
		let str = e.toString();
		n = str.length >= 32 ? str[0] : 0;
		return n;
	}

	//二进制位数补全32位
	function number32(e) {
		let n = 0;
		if (e.length >= 32) {
			n = 0;
		} else {
			n = 32 - e.length;
			for (let i = 0; i < n; i++) {
				e = '0' + e;
			}
		}

		return e;
	}
	//16进制位数补全8位
	function number8(e) {
		let n = 8 - e.length;
		for (let i = 0; i < n; i++) {
			e = '0' + e;
		}

		return e;
	}

	//type==0,转为wgs-04--与--==1转为火星地址
	function addressTrans(data, type) {
		let path = [];

		// console.log('转换前---', data)

		if (type == 0) {
			path = gcj02towgs84(parseFloat(data[1]), parseFloat(data[0]));
		} else if (type == 1) {

			let arr = wgs84togcj02(parseFloat(data[0]), parseFloat(data[1]));
			path = [arr[1], arr[0]]
		}


		// console.log('转换后---', path)
		return path;


	}

	//获取下发每个围栏点位
	function setPoints(data) {
		let arr = [];
		let str = '';
		let Arr = [];
		for (let i = 0; i < data.length; i++) {
			arr = data[i].split(':');

			arr = addressTrans(arr, 0);


			for (let j = 0; j < arr.length; j++) {
				let num = arr[j] + '';

				arr[j] = Math.abs(arr[j]);
				let m = parseInt(arr[j] * Math.pow(10, 6));

				str = convert2(m, 2);

				str = number32(str);
				if (num.indexOf('-') == 0) {
					str = '1' + str.slice(1)
				}

				arr[j] = convert1(str, 2);
				arr[j] = convert2(arr[j], 16);
				if (arr[j].length < 8) {
					arr[j] = number8(arr[j]);
				}
			}

			Arr.push(arr.join(''));


		}
		return Arr;
	}

	//下发数据整理
	function setFenceList(data) {
		centerDialogVisible.value = true;
		let arr = [];
		let Arr = [];
		let res = [];
		let str = 'A55A';
		let j = 0;
		let n = '';
		let m = '';

		//数据剪切拼接
		data.forEach((obj, i) => {
			res = obj.localtion.split(',');
			//arr是已处理好的区域
			arr = setPoints(res);
			n = convert2(Number(obj.speed), 16);
			m = convert2(arr.length, 16);
			j = convert2(i + 1, 16);
			if (obj.speed < 16) {
				n = '0' + n;
			}
			if (arr.length < 16) {
				m = '0' + m;
			}
			if (i + 1 < 16) {
				j = '0' + j;
			}

			let path = str + j + n + m + arr.join('');
			Arr.push(path)
		})

		//处理后的所有区域围栏数据，开始下发
		setData(Arr, 0);
	}

	//点击数据下发操作按钮
	/*
	  1、每次不能超过1024字节长度，一个点占4个字节，固定区域标识符5字节，以及续包标识符d=4字节:
	  2、假设每个区域取3个点成区域，区域a=12字节,固定字符c=5字节,即区域发送固定数据c=a+b=17字节:
	  3、每次分包下发，减去固定占有字节1024-d=1020:
	  4、由此可得限制围栏数量m=1020/17=60个区域;
	*/
	function sendFence() {

		//设置每次不能超过180个点，大概取值720个点;
		if (editActive.value) {
			ElMessage.error('Please finish editing first！')
			return;
		}
		if (!tableData.value || tableData.value.length <= 0 || multipleSelection.value.length <= 0) {
			ElMessage.error('The delivery area cannot be empty！')
			return;
		}
		if (sum.value >= 720) {
			ElMessage.error('The number of regions exceeds storage！')
			return;
		}
		centerDialogVisible.value = true;
		newData.value = '-1';
		// total.value = tableData.value.length;
		total.value = multipleSelection.value.length;
		//一次不能超过1024，下发数据分批处理截断

		setFenceList(multipleSelection.value)
		//开始下发

	}

	//设备开始准备下发数据
	function setData(data, s) {
		//获取数据，判断点数是否超过长度
		//i==0时，代表数据开始预备下发，只有大于0时正式向设备下发数据


		let paths = 0;
		let k = 0;
		let arr = [];
		for (let i = s; i < data.length; i++) {
			paths = paths + data[i].length;
			let num = paths / 2;
			if (num >= 1020) {
				i--;
				myMap.set(k, arr);
				k++;
				arr = [];
				paths = 0;


			} else {
				arr.push(data[i]);
				if (i >= data.length - 1) {

					// k = i;
					myMap.set(k, arr);
					k++;
					arr = [];
					paths = 0;
				}
			}

		}

		//分包完毕，开始发送
		// console.log('分包是？', myMap)

		sendArea(myMap, 0);

	}
	//
	async function sendArea(data, key) {
		if (key < 0) {
			centerDialogVisible.value = false;
			ElMessage.error('Operation failure！')

			return
		}
		if (key == 'ok') {
			centerDialogVisible.value = false;
			ElMessage.success('Success！');

			// tableData.value.forEach(item => {
			// 	item.type = 2;
			// })
			onclick(0);
			return
		}

		let str = '';
		let m = convert2(total.value, 16);
		if (total.value < 16) {
			m = '0' + m;
		}
		//发送数据
		let arr = data.get(key);

		let res = arr.join('');
		let n = convert2(res.length / 2, 16);
		// console.log('发送第' + key + '包长度:', n, res.length);
		switch (n.length) {
			case 1:
				n = '000' + n;
				break;
			case 2:
				n = '00' + n;
				break;
			case 3:
				n = '0' + n;
				break;
			default:
				break;
		}
		if (key == 0) {
			str = '00' + m + n + res;
		} else {
			str = '01' + m + n + res;
		}

		// console.log('发送第' + key + '包:', str);
		//发送数据返回值
		inputData.value = 'SAREA,';
		let tag = await sendData(str);

		if (tag == 1) {
			let timer2 = setTimeout(() => {

				if (newData.value && newData.value != '-1') {
					//返回值'NEXT PLEASE'进行下一步
					if (newData.value.indexOf("PLEASE") != -1) {
						key++;
						if (!data.get(key)) {
							key = 'ok'
						}

						sendArea(data, key);
					} else {
						centerDialogVisible.value = false;
						ElMessage.error('Abnormal data delivery! Please re-operate')
					}
				} else if (newData.value == '-1') {
					centerDialogVisible.value = false;
					ElMessage.error('Response time out! Restart the device to reset the Settings')
				}
				clearTimeout(timer2);
			}, 5000)
		} else {
			centerDialogVisible.value = false;
		}

	}

	//点击同步按钮
	function onclick(e) {
		if (editActive.value) {
			ElMessage.error('Please finish editing first！')
			return;
		}
		centerDialogVisible.value = true;
		total.value = tableData.value.length;
		// tableData.value = [];
		// for (var key in userMaps) {
		// 	userMaps[key].setMap(null);
		// }
		// userMaps = {};
		numpook.value = 1;
		deviceList.value = [];
		mergedArray1.value = '';
		serialDatas.value = '';
		mergedArrays.value = new Uint8Array([]);
		queryFences(e)

	}
	//同步开始
	async function queryFences(e) {
		//发送数据  RAREA,0*
		numpook.value++;
		if (e == 0) {

			inputData.value = 'RAREA,0*';
		} else {
			inputData.value = 'RAREA,1*';
		}
		newData.value = '-1';

		let tag = await sendData();
		if (tag == 1) {

			let timer = setTimeout(() => {
				if (newData.value) {
					let flg = -1;
					if (newData.value.indexOf("OR 0") != -1) {
						centerDialogVisible.value = false;
						ElMessage.info('no data');
					} else if (newData.value.indexOf("OR 1") != -1 || newData.value.indexOf("OR 2") != -1 ||
						newData.value.indexOf("OR 3") != -1) {
						centerDialogVisible.value = false;
						clearMaps()
						ElMessage.error('Data error! Please re-operate or reset data');
					} else {
						flg = getFences(newData.value, numpook.value);
						// console.log('同步完成？', flg == 0);
						// if (flg == 0) {
						// 	queryFences(1);
						// }
					}


				} else if (newData.value == '-1') {
					clearMaps();
					centerDialogVisible.value = false;
					ElMessage.error('Response time out! Restart the device to reset the Settings')
				}
				clearTimeout(timer);
			}, 5000)

			// onclick(1);
		} else {
			// ElMessage.error('发送失败！请重试或重启设备重新设置')
			centerDialogVisible.value = false;
		}
	}

	//获取设备数据
	async function getFences(e, i) {
		drawingManager.value.setDrawingMode(null);

		let Arr = [];


		//数据接口,接口返回值

		deviceList.value = deviceList.value.concat(await needList(e, i));
		// console.log('同步区域:', total.value, '--已获取区域:', deviceList.value.length);

		//根据返回值判断，区域长度,区域长度已经相等,结束递归，不再向设备发送数据，
		if (deviceList.value.length >= total.value) {
			centerDialogVisible.value = false;
			const arr = tableData.value[tableData.value.length - 1].localtion.split(',');
			const m = arr[0].split(':');
			const newCenter = {
				lat: Number(m[0]),
				lng: Number(m[1])
			}; // 新的中心坐标
			map.setCenter(newCenter);

			drawingManager.value.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
			// tableData.value = deviceList.value;

			ElMessage.success('Success！');
			return 1;
		} else {
			mergedArray1.value = '';
			serialDatas.value = '';
			mergedArrays.value = new Uint8Array([]);
			queryFences(1);
			// console.log('tableData.value---------: ', tableData.value)
			// centerDialogVisible.value = false;
			drawingManager.value.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
			return 0;
		}

		//数据、返回值判断未同步完成==>重复动作
		//getFences(2)
	}
	//同步区域地址判断是否相同
	function repeatData(data, s) {
		let newArr = [];
		let str = data.join(',');
		let n = 0;
		let data2 = JSON.parse(localStorage.getItem('fences')) || [];

		for (let j = 0; j < data2.length; j++) {

			if (str == data2[j].wgs) {
				for (let i = 0; i < tableData.value.length; i++) {

					if (str == tableData.value[i].wgs) {
						tableData.value[i].type = 2;
						tableData.value[i].speed = s;
						n = 1;
					}
				}
			}
		}

		return n;


	}
	//同步到的数据处理
	async function needList(str, q) {
		// str ='0009020aA55A011e0386c4db2d0331fb3986c5695503318afe86c4bf030331a93fA55A021e0386c432320331e42686c47bf50331ac6e86c3ff3c0331a93fA55A031e0386c358f00331de9386c37a770331b79386c313330331bb8eA55A041e0386c51229033174b286c5467603312f6686c4d47803313362A55A051e0386c4378f0331764a86c47697033141b986c3f481033141b9A55A061e0386c36fbc0331718386c3b828033138f586c2ec4f03313b59A55A071e0386c51e3b0331039386c5568e0330d95686c4db2d0330d956A55A081e0386c436380331085b86c46bdd0330dfb686c3f07b0330e3b2A55A091e0386c34b8603310f8786c3b01c0330e21a86c2dadf0330e2e6';
		let data = []; //返回的数据
		let arr = []; //返回的数据
		//得到数据str，str数据进行判断
		let tip = String(str.substring(0, str.indexOf("a55a")));
		let num = convert1(tip.substring(2, 4), 16);
		total.value = num;
		// console.log('设备区域---', tip, num)

		const regex = /a55a(.*)/;
		let reg1 = /.{1,16}/g;
		let reg2 = /.{1,8}/g;
		const match = str.match(regex);
		if (match) {
			const result = match[1];
			arr = result.split('a55a');
			// console.log('设备总区域---', arr);
			let newArr = [];

			for (let i = 0; i < arr.length; i++) {
				let item = String(arr[i]);

				// 当前的key
				let def = [];
				let def2 = [];
				let def3 = [];
				let localtion = item.substring(6);
				let res = localtion.match(reg1);
				let speed = convert1(item.substring(2, 4), 16);

				for (let s of res) {
					let n = s.match(reg2);
					let loc = tidyPaths(n)[0];
					let loc1 = tidyPaths(n)[1];


					let obj = {
						"lat": Number(loc[0]),
						"lng": Number(loc[1])
					}
					def.push(obj);
					def2.push(loc.join(':'))
					def3.push(loc1.join(':'))
				}
				let key = q + 2 + '_' + i;

				let flg = await repeatData(def3, speed);

				if (flg == 0) {
					// console.log('地址------：', key)
					// 新建一个polygon
					let polygon = new google.maps.Polygon({
						paths: def
					});
					// 设置地图
					polygon.setMap(map);
					// 监听操作
					polygonListener(key, polygon, speed);
				}


			}
			return arr;
		} else {
			// console.log('未找到匹配的字符串');
			centerDialogVisible.value = false;
			ElMessage.error('Synchronization failed! Please synchronize or restart the device')
			return arr;
		}


	}
	//同步区域地址处理
	function tidyPaths(n) {
		let data = [convert1(n[0], 16), convert1(n[1], 16)];
		let a = number32(convert2(data[0], 2));
		let b = number32(convert2(data[1], 2));

		if (getNumber(a) == 1) {
			a = '0' + a.substring(1);
			data[0] = '-' + convert1(a, 2);
		}
		if (getNumber(b) == 1) {
			b = '0' + b.substring(1);
			data[1] = '-' + convert1(b, 2);
		}

		data[0] = data[0] / Math.pow(10, 6);
		data[1] = data[1] / Math.pow(10, 6);

		n = addressTrans(data, 1);

		return [n, data];
	}

	async function delAllFences() {
		centerDialogVisible.value = true;
		inputData.value = 'SAREA,';
		let flg = await sendData('0000');
		if (flg == 1) {
			let timer3 = setTimeout(() => {
				if (newData.value.indexOf('PLEASE') != -1) {

					clearMaps();

					centerDialogVisible.value = false;
					ElMessage.success('Success');
				} else {
					centerDialogVisible.value = false;
					ElMessage.error('Reset failed! Please check operation');
				}
			}, 3000)
		} else {
			centerDialogVisible.value = flse;
			// ElMessage.error('发送失败！请重试或重启设备重新设置');
		}

	}

	function nameInput(e) {

	}
	//点击了编辑
	function edit(row, i) {
		if (editActive.value) {
			ElMessage.error('Complete the previous step first！')
			return;
		}

		storageObj.value.data = JSON.parse(JSON.stringify(row));

		row.flg = !row.flg;
		editActive.value = true;
		cancleFlg.value = true;
		storageObj.value.polygon = userMaps.value[row.id];

		const arr = row.localtion.split(',');
		const m = arr[0].split(':');
		const newCenter = {
			lat: Number(m[0]),
			lng: Number(m[1])
		}; // 新的中心坐标
		map.setCenter(newCenter);
		map.setZoom(14);
		userMaps.value[row.id].setDraggable(true);
		userMaps.value[row.id].setEditable(true);
		// console.log('、、、', userMaps[row.id])
		drawingManager.value.setDrawingMode(null);
	}
	//点击了取消
	function cancel(row, i) {

		row.flg = !row.flg;
		row.speed = storageObj.value.data.speed;
		userMaps.value[row.id].setMap(null);

		// userMaps.value[index]=storageObj.value.polygon;
		storageObj.value.polygon.setMap(map);
		editActive.value = false;
		userMaps.value[row.id].setDraggable(false);
		userMaps.value[row.id].setEditable(false);
		drawingManager.value.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
	}
	//点击了删除
	async function del(row, i) {


		if (row.type == 1) {
			clearMap(row);
			tableData.value.splice(i, 1);
			localList.value.forEach((obj, j) => {
				if (obj.id == row.id) {
					localList.value.splice(j, 1);
				}
			})
			localStorage.setItem('fences', JSON.stringify(localList.value));

		} else {
			let flg = await repeatData(row.wgs.split(','), row.speed);
			if (flg == 0) {
				clearMap(row);
				tableData.value.splice(i, 1);

			} else {
				tableData.value[i].type = 1;
				localList.value.forEach((obj, j) => {
					if (obj.id == row.id) {
						tableData.value[i].speed = obj.speed;
					}
				})
			}
		}



	}
	//点击了确认
	function ok(row, i) {
		row.flg = !row.flg;
		editActive.value = false;

		if (row.type == 1) {

			localList.value.forEach((obj, i) => {
				if (obj.id == row.id) {
					localList.value[i] = row;
				}
			})

			localStorage.setItem('fences', JSON.stringify(localList.value));
		}

		// let index='key_'+i;
		userMaps.value[row.id].setDraggable(false);
		userMaps.value[row.id].setEditable(false);
		drawingManager.value.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);

	}
	//经纬度
	//获取地图经纬度
	function setLocation(data) {

		if (!data || data.length <= 0) {
			return [];
		}
		let arr = [];
		sum.value = 0;

		defaults.value = [];
		data.forEach((item, i) => {
			item.flg = false;
			// this.editingRow.push(item.flg);
			let def = [];

			arr = item.localtion.split(',');
			sum.value = sum.value + arr.length;
			for (let s of arr) {
				let n = s.split(':');
				let obj = {
					"lat": Number(n[0]),
					"lng": Number(n[1])
				}
				def.push(obj);
			}
			defaults.value.push(def);

		})

		let n = JSON.stringify(defaults.value);
		return n;
	}
	//初始化数据加载
	function getAllFence(data) {
		if (!data || data.length <= 0) {
			return;
		}

		// 解析
		var json = JSON.parse(data);
		// console.log('应该只有准确数据才对？',json)
		// 为空不继续
		if (!json || json.length == 0) {
			return;
		}
		// 数量起始值更新
		num.value = json.length;


		// 循环
		for (let i = 0; i < json.length; i++) {
			// 当前的key
			let key = '1_' + i;

			// 新建一个polygon
			let polygon = new google.maps.Polygon({
				paths: json[i]
			});

			// 设置地图
			polygon.setMap(map);
			// 监听操作
			polygonListener(key, polygon);

		}
	}

	//围栏完成
	function mapPolygoncomplete(map) {


		let n = tableData.value.length <= 0 ? 0 : tableData.value.length;

		// 当前的key
		key.value = '1_' + n;
		// 监听操作

		polygonListener(key.value, map);

	}

	//围栏操作事件
	function polygonListener(key, polygon, s) {

		// 插入
		pushMap(key, polygon, s);

		// 当拖动完成的时
		polygon.addListener('dragend', () => pushMap(key, polygon));
		// 移动角的时候
		polygon.addListener('mouseover', () => pushMap(key, polygon));
		// 双击 删除
		// polygon.addListener('dblclick', () => this.clearMap(key, polygon));

	}
	//围栏处理
	function pushMap(index, MVCArray, s) {
		const j = index.indexOf('_');
		const k = Number(index.substring(j + 1));
		const key = index;
		userMaps.value[index] = MVCArray;

		storageObj.value.key = index;

		let data = userMaps.value[index].getPath().getArray().map(index => index.toJSON());

		let str = '';
		let str2 = '';
		let str3 = '';
		let data2 = [];
		for (let i in data) {

			str += `${data[i].lat}:${data[i].lng}`;
			data2 = addressTrans([data[i].lat, data[i].lng], 0);

			let o = data[i].lat.toString().slice(0, data[i].lat.toString().indexOf('.') + 7);
			let p = data[i].lng.toString().slice(0, data[i].lng.toString().indexOf('.') + 7);
			let o1 = parseInt(data2[1] * Math.pow(10, 6));
			let p1 = parseInt(data2[0] * Math.pow(10, 6));
			str3 += `${parseFloat(p1/Math.pow(10, 6))}:${parseFloat(o1/Math.pow(10, 6))}`
			str2 += `${parseFloat(o)}:${parseFloat(p)}`
			if (i < data.length - 1) {
				str += ',';
				str2 += ',';
				str3 += ',';
			}
		}

		let tag = 0;
		// console.log('index标识符------: ', index)
		tableData.value.forEach((res, p) => {
			if (res.id == index) {
				res.localtion = str;
				tag = 1;
			}
		})

		localList.value.forEach(res => {
			if (res.id == index) {
				res.localtion = str;
			}
		})

		if (tag === 0) {
			let m = [str, str2, str3]
			mapStr(m, index, s);
		}
		// if (tableData.value && tableData.value[k]) {
		// 	tableData.value[k].localtion = str
		// } else {
		// 	mapStr(str, index,s);
		// 	// userMaps.value[index].setDraggable(true);
		// 	// userMaps.value[index].setEditable(true);
		// 	// drawingManager.value.setDrawingMode(null);
		// }

	}
	//将地图坐标进行拼接
	function mapStr(str, key, s) {
		// console.log('str: ', str)
		let obj = {
			localtion: str[0],
			speed: s ? s : 30,
			flg: false,
			id: key,
			type: s ? 2 : 1,
			str: str[1],
			wgs: str[2],
		};
		// editActive.value = true;
		// this.editingRow.push(true);
		tableData.value.push(obj);
		sum.value = sum.value + str[0].split(',').length;

		if (obj.type == 1) {

			localList.value.push(obj);
			// console.log(obj.type)
			localStorage.setItem('fences', JSON.stringify(localList.value));
		}

		storageObj.value.data = obj;
		storageObj.value.key = key;
	}
	//对单个围栏的删除
	function clearMap(row) {

		if (userMaps.value[row.id] !== undefined) {
			// this.index = index;
			// key.value = 'key_' + index;
			userMaps.value[row.id].setVisible(false);
			userMaps.value[row.id].setMap(null);
			userMaps.value[row.id] = undefined;
			delete userMaps.value[row.id];
		}
	}
	// 清楚所有轨迹
	function clearMaps() {
		let data = JSON.parse(localStorage.getItem('fences')) || [];
		let arr = setLocation(data)
		if (tableData.length > 0) {
			// for (var key in userMaps.value) {
			// 	userMaps.value[key].setMap(null);
			// }

		}

		for (var key in userMaps.value) {
			if (userMaps.value[key]) {
				userMaps.value[key].setMap(null);
			}

		}
		userMaps.value = {};
		tableData.value = [];
		localList.value = [];
		getAllFence(arr);
	}

	/* 
	生命周期函数
	当挂载完成之后，对浏览器是否支持本网页进行检查
	*/
	onMounted(() => {
		let data = JSON.parse(localStorage.getItem('fences')) || [];

		total.value = data.length;

		// tableData.value = JSON.parse(JSON.stringify(data)) || [];
		// localList.value = JSON.parse(JSON.stringify(data)) || [];
		initMap();


		if ("serial" in navigator) {

		} else {
			ElNotification({
				title: 'Low browser version',
				message: 'Your browser version is too low and may not support the use of a browser serial port',
				type: 'error',
				duration: 20000
			})
		}
	})
	//搜索
	function selectPrediction(prediction) {
		localValue.value = prediction.description;
		predictions.value = [];
		const that = this;
		// 创建 PlacesService 对象
		const placesService = new google.maps.places.PlacesService(map);
		// 获取地点的 Place ID
		const placeId = prediction.place_id;
		// 发起 Places API 请求
		placesService.getDetails({
			placeId: placeId
		}, function(place, status) {
			if (status === google.maps.places.PlacesServiceStatus.OK) {
				// 获取地点的经纬度坐标
				const newCenter = {
					lat: place.geometry.location.lat(),
					lng: place.geometry.location.lng()
				}; // 新的中心坐标
				map.setCenter(newCenter);
				map.setZoom(14);
			} else {
				alert("no");
			}
		});
	}

	async function handleInput() {

		const autocompleteService = new google.maps.places.AutocompleteService();
		if (!localValue.value) {
			return
		}
		let data = [];
		predictions.value = [];
		await autocompleteService.getPlacePredictions({
				input: localValue.value
			},
			(predictions, status) => {

				if (status === google.maps.places.PlacesServiceStatus.OK) {

					predictions.value = predictions;
					data = predictions;
				} else {
					data = [];
				}
			}
		);
		predictions.value = data


	}
	//地图初始化
	function initMap() {
		// 创建一个map

		const script = document.createElement('script');
		script.src =
			'https://maps.googleapis.com/maps/api/js?key=AIzaSyDd2hHgypPewatker1Om5w1bjrhPiXNfR8&libraries=drawing,places&v=weekly&language=en_us';

		script.onload = () => {
			// Google Maps API加载完成后的回调函数
			// 可以在这里使用google对象和相关方法
			map = new google.maps.Map(document.getElementById("gMaps"), mapOptions);
			// 配置
			var options = {
				drawingMode: google.maps.drawing.OverlayType.POLYGON,
				drawingControl: false
			};
			//初始化

			let data = setLocation(JSON.parse(localStorage.getItem('fences')));
			let list = JSON.parse(localStorage.getItem('fences')) || [];
			if (list.length > 0) {
				const arr = list[list.length - 1].localtion.split(',');
				const m = arr[0].split(':');
				const newCenter = {
					lat: Number(m[0]),
					lng: Number(m[1])
				}; // 新的中心坐标
				map.setCenter(newCenter);
				map.setZoom(13);
			}
			// console.log('//////', localList.value)

			getAllFence(data);
			drawingManager.value = new google.maps.drawing.DrawingManager(options);
			drawingManager.value.setMap(map);
			// 当选择完成的时候

			drawingManager.value.addListener('polygoncomplete', (res) => mapPolygoncomplete(res));
		};

		document.head.appendChild(script);

		// map = new google.maps.Map(document.getElementById("gMaps"), mapOptions);
		// // 配置
		// var options = {
		// 	drawingMode: google.maps.drawing.OverlayType.POLYGON,
		// 	drawingControl: false
		// };
		// //初始化

		// let data = setLocation(JSON.parse(localStorage.getItem('fences')));
		// let list = JSON.parse(localStorage.getItem('fences')) || [];
		// if (list.length > 0) {
		// 	const arr = list[list.length - 1].localtion.split(',');
		// 	const m = arr[0].split(':');
		// 	const newCenter = {
		// 		lat: Number(m[0]),
		// 		lng: Number(m[1])
		// 	}; // 新的中心坐标
		// 	map.setCenter(newCenter);
		// 	map.setZoom(13);
		// }
		// // console.log('//////', localList.value)

		// getAllFence(data);
		// drawingManager.value = new google.maps.drawing.DrawingManager(options);
		// drawingManager.value.setMap(map);
		// // 当选择完成的时候

		// drawingManager.value.addListener('polygoncomplete', (res) => mapPolygoncomplete(res));


	}

	const connectToSerialPort = async () => {
		try {
			// 提示用户选择一个串口
			port.value = await navigator.serial.requestPort();

			// 获取用户之前授予该网站访问权限的所有串口。
			ports.value = await navigator.serial.getPorts();
			navigator.serial.addEventListener("disconnect", (event) => {

				port.value = null;
				ElMessage.error('Disconnect！')
				// console.log('出错了！',event);
				// TODO: Remove |event.target| from the UI. // 如果打开了串行端口，还会观察到流错误。 
			});

			// console.log(port.value, ports.value);
			// console.log(port.value);
			// 等待串口打开
			await port.value.open({
				baudRate: 9600,
				bufferSize: 1600000
			});


			// const bufferSize = 65535; // 1kB
			// let buffer = new ArrayBuffer(bufferSize);

			// // Set `bufferSize` on open() to at least the size of the buffer.
			// await port.value.open({ baudRate: 9600, bufferSize });

			// const reader = port.value.readable.getReader({ mode: "byob" });
			// while (true) {
			//   const { value, done } = await reader.read(new Uint8Array(buffer));
			//   if (done) {
			//     break;
			//   }
			//   buffer = value.buffer;
			//   // Handle `value`.
			// }


			// console.log(typeof port.value);
			ElMessage({
				message: "Successfully connected",
				type: "success",
			});
			// readData(port.value);
			readData();
		} catch (error) {
			// 处理连接串口出错的情况
			port.value = null;
			ElMessageBox.alert(error +
				'<br>Please disconnect the serial port and reconnect the USB serial port', 'Tip', {
					// if you want to disable its autofocus
					// autofocus: false,
					confirmButtonText: 'OK',
					dangerouslyUseHTMLString: true,
				})
			// console.log("串口出错:", error);
		}
	};


	function updateInputData2(data) {
		let array = new Uint8Array(data); // event.data.buffer就是接收到的inputreport包数据了
		let hexstr = "";
		for (const data of array) {
			hexstr += (Array(2).join(0) + data.toString(16).toUpperCase()).slice(-2) + " "; // 将字节数据转换成（XX ）形式字符串
		}
		mergedArray1.value += hexstr;

		// 将十六进制字符串转换为数组
		const hexArray = mergedArray1.value.split(' ');

		// 将每个十六进制数转换为十进制数
		const decimalArray = hexArray.map(hex => parseInt(hex, 16));

		// 创建 Uint8Array
		mergedArrays.value = new Uint8Array(decimalArray);
		// console.log('此时读取数据:', mergedArray1.value);
	}

	const readData = async () => {
		reader.value = port.value.readable.getReader();
		newData.value = '-1';
		// console.log('///', reader);
		// 监听来自串口的数据
		while (true) {
			const {
				value,
				done
			} = await reader.value.read();

			if (done) {
				// 允许稍后关闭串口
				// console.log('此时停止循环:', value);
				reader.value.releaseLock();
				break;
			}
			updateInputData2(value);
			// 获取发送的数据
			// let serialData0 = new TextDecoder().decode(mergedArray1.value);
			// if (serialData0.length > 0) {
			// 	var mergedArray2 = new Uint8Array(mergedArray1.value.length + value.length);
			// 	mergedArray2.set(mergedArray1.value.length);
			// 	mergedArray2.set(value, mergedArray1.value.length.length);

			// 	mergedArray1.value = mergedArray2;
			// } else {
			// 	mergedArray1.value = value;
			// }


			const serialData = new TextDecoder().decode(mergedArrays.value);
			// serialDatas.value = serialDatas.value + serialData;
			let data = serialData.split(',');
			let data2 = mergedArrays.value.slice(6);
			// let str = '';
			let arr = '';


			// console.log('此时发送数据:', inputData.value);
			// console.log('此时读取数据:', value);
			// console.log('此时读取拼接后的数据:', mergedArrays.value);
			// console.log('此时读取拼接后的数据2:', data2);
			// console.log('此时转换后的数据:', data);
			// // console.log('返回数据截取2:', serialDatas.value);
			// // console.log('返回数据截取3:', data);
			// // if (data[0]data[0].length > =5) {

			if (data[0].indexOf('RAREA') != -1) {
				// let ee=new TextEncoder().encode(serialDatas.value);

				arr = uint8Array11(mergedArray1.value);

				// data2.forEach(item => {
				// 	let n = String(convert2(item, 16));
				// 	let m = n.length >= 2 ? n : '0' + n;
				// 	arr.push(m)
				// })
				// console.log('获取到的数据2:', arr)
				newData.value = arr;
				// console.log('转换获取到的数据: ');
				// console.log(newData.value);
			} else {
				newData.value = serialData;
			}

			// console.log('获取到ee数据: ')
			// console.log(serialData);


			// }


			// // str = uint8Array(value);
			// console.log('获取到原始数据: ')
			// console.log(value);
			// // console.log('TextDecoder转换了: ')
			// // console.log(serialData);
			// console.log('转换获取到的数据: ');
			// console.log(newData.value);
			// // console.log('原始得到返回数据:', value.length, value)

		}

	};

	function uint8Array11(value) {
		// console.log('获取到原始数据: ',value)
		let arr = value.split(' ');
		let Arr = arr.slice(6);
		let uint8Array = Arr.join('');
		// console.log('获取到原始数据2: ',uint8Array.toLowerCase())
		return uint8Array.toLowerCase();
	}

	function uint8Array(uint8Array) {
		return Array.prototype.map
			.call(uint8Array, (x) => ('00' + x.toString(16)).slice(-2))
			.join('');
	}

	function hexToUint8Array(hex) {
		var array = [];
		for (var i = 0; i < hex.length; i += 2) {
			array.push(parseInt(hex.substr(i, 2), 16));
		}
		return new Uint8Array(array);
	}
	//
	const sendData = async (str) => {

		// if (port.value && port.value.isOpen) {
		if (port.value) {
			if (inputData.value) {
				const writer = port.value.writable.getWriter();
				let arr = [];
				if (str) {
					let data = new TextEncoder().encode(inputData.value);

					let arr = hexToUint8Array(str);
					let arrayOne = new Uint8Array(data);
					let arrayTwo = new Uint8Array(arr);

					let mergedArray = new Uint8Array(arrayOne.length + arrayTwo.length);
					mergedArray.set(arrayOne);
					mergedArray.set(arrayTwo, arrayOne.length);
					// console.log("发送给设备数据------", mergedArray);
					await writer.write(mergedArray);
				} else {
					await writer.write(new TextEncoder().encode(inputData.value));
				}
				// console.log("发送数据------", inputData.value);
				await writer.close();
				return '1';
			} else {
				// return ElMessage({
				// 	message: "输入需要发送的数据内容",
				// 	type: "warning",
				// 	showClose: true,
				// 	grouping: true,
				// 	duration: 2000,
				// });
				return 0;
			}
		} else {
			ElMessage({
				message: "The serial port is not open！",
				type: "warning",
				showClose: true,
				grouping: true,
				duration: 2000,
			});
			return 0;
			// console.error("串口未连接或未打开！");
		}
	};

	// 断开接口
	const cutPort = async () => {
		if (!port.value) {
			return;
		}
		if (port.value !== "") {
			await reader.value.cancel();
			await port.value.close();
			port.value = "";
			// console.log("断开串口连接");
			ElMessage({
				message: "success",
				type: "success",
			});
		} else {
			ElMessage({
				message: "Please connect",
				type: "warning",
				showClose: true,
				grouping: true,
				duration: 2000,
			});
			console.error("The serial port is not open or connected！");
		}
	};
</script>

<style lang="scss" scoped>
	#gMaps {
		width: 75%;
		height: 100%;
		border: 1px #bbbbbb;
	}

	.left {
		i {
			margin: auto;
		}
	}

	.el-header {
		width: 100%;
		height: 60px;
		background-color: #2d3a4b;
		line-height: 60px;
		color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.left,
	.right {
		display: flex;
	}

	.el-main {
		height: 92vh;
		padding: 15px;

		.el-row {
			// height: 15vh;

			div {
				padding: auto;
				text-align: center;

				.el-tag {
					height: 80%;
					width: 50%;
				}
			}
		}
	}

	.flex-item2 {
		position: absolute;
		top: 80px;
		right: 80px;
		z-index: 99;
	}

	.autocomplete-list {
		height: 200px;
		overflow: auto;

		ul {
			li {
				margin: 10px 0;
			}
		}
	}

	:deep .el-overlay {
		background-color: rgba(0, 0, 0, 0) !important;
	}
</style>